var SparkDigital = SparkDigital || {};

SparkDigital = (function () {

    /***********************************************************
    // GLOBAL - FOR STORING REUSABLE VARIABLES (EG. DOM)
    ***********************************************************/
    var global = {

            debug_mode: $('body').hasClass('debug-mode'), // Debug on-off state

            site_lang:  $('html').attr('lang'), // get site language

            ajax_endpoints: window["ajax_endpoints"],

            // Define ones that are being called everywhere
            elements: {
                body: $("body"),
                iframes: $("body.single iframe"), // Be specific where the iframes might be added
                inlineSmoothScroll: $("a[smooth-scroll], a[href^='#']"), // Select all inline anchors
                inlinePopUps: $('[inline-modal]'), // For all inline popups
                iframePopUps: $('[inline-youtube], [inline-video], [inline-gmaps]'), // For all iframe popups
                lazyload: $('[data-bg], img[data-src], iframe[data-src], video[data-src], .lazy'), // Lazy load bg images, img/iframe/video HTML elements. 
            },

        },
        /***********************************************************
        // COMPONENTS THAT ARE USED ON MORE THAN ONE PAGE & CALLABLE
        ***********************************************************/
        components = {
            modal: {
                inline: function (elements) {
                    var elements = $(elements).length != 0 ? $(elements) : global.elements.inlinePopUps;
                    
                    // if ($(elements).length > 0) {
                    //     $(elements).magnificPopup({
                    //         type: 'inline',
                    //         closeOnContentClick: true,
                    //         fixedContentPos: true,
                    //         mainClass: 'mfp-fade',
                    //         removalDelay: 160,
                    //     });
                    // }

                },
                iframe: function (elements) {
                    var elements = $(elements).length != 0 ? $(elements) : global.elements.iframePopUps;

                    if ($(elements).length > 0) {
                        // $(elements).magnificPopup({
                        //     //disableOn: 700,
                        //     type: 'iframe',
                        //     mainClass: 'mfp-fade',
                        //     removalDelay: 160,
                        //     preloader: false,
                        //     fixedContentPos: false
                        // });
                    }
                },
                gallery: function (elements) {
                    var elements = $(elements).length != 0 ? $(elements) : global.elements.galleries;
                    //console.log($(elements).length);
                    if ($(elements).length > 0) {

                        $(elements).magnificPopup({
                            type: 'image',
                            callbacks: {
                                open: function () {
                                    $('.headerFix').css('padding-right', 15);
                                }, //Fix for header moving when popup triggered
                                close: function () {
                                    $('.headerFix').css('padding-right', 0);
                                }
                            },
                            image: {
                                verticalFit: true,
                                titleSrc: function (item) {
                                    return item.el.parents('.gallery-item').find('.gallery-caption').text();
                                }

                            },
                            gallery: {
                                enabled: true,
                            },
                            mainClass: 'mfp-fade',
                            removalDelay: 160,
                            fixedContentPos: true,
                        });

                        // Separate remote trigger button to fire the magnificPopup gallery
                        var galleryHeaderImage = $(".postTopImage");
                        if ($(galleryHeaderImage).length > 0) {
                            $(galleryHeaderImage).click(function () {
                                $(elements).eq(0).trigger('click');
                                return false;
                            });
                        }
                    }

                },
            },
            
            smoothScroll: function (elements) {

                var elements = $(elements).length != 0 ? $(elements) : global.elements.inlineSmoothScroll;
                if ($(elements).length > 0) {
                    elements.click(function () {

                        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname && this.hash.slice(1) != "#" && this.hash.slice(1) != "" ) {
                            var target = $(this.hash);                            
                            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');

                            if (target.length) {

                                // Target y position within the browser (24px is faux offset).
                                var scroll_top = target.offset().top;

                                // Sticky menu
                                scroll_top = scroll_top  -  ( $("header .sticky").length > 0 ? parseInt( $("header .sticky").css("height") ) : 0 );

                                // Wordpress Adminbar (Only on desktop, ignored on mobile)
                                if($("#wpadminbar").length > 0 ){
                                    scroll_top = scroll_top -  ( $("#wpadminbar").css("postiion") == "fixed" ? parseInt( $("#wpadminbar").css("height") ) : 0 );
                                }

                                $('html, body').animate({
                                    scrollTop: scroll_top
                                }, 1500);
                                return false;
                            }
                        }

                    });
                }
            },
            stickyHeader: function(){
                // Uses Polyfill for browsers that does not support IntersectionObserver API
                
                //  Navigation target
                var sticky_nav = document.querySelector(".main-header");

                // Sticky Target
                var sticky_target = document.querySelector("#sticky-header-target");
                
                var observer = new IntersectionObserver(function(entries) {
                    // no intersection with screen
                    if(entries[0].intersectionRatio === 0) {
                        $(sticky_nav).addClass('sticky');
                        if (global.debug_mode) {
                            console.log("Sticky Target: Outside");
                        }
                    }
                    // fully intersects with screen
                    else if(entries[0].intersectionRatio === 1){
                        $(sticky_nav).removeClass('sticky');
                        if (global.debug_mode) {
                            console.log("Sticky Target: Inside");
                        }
                    }
                
                }, { threshold: [0,1] });
                
                observer.observe(sticky_target);
            },

            // Compiles HandleBars
            handlebars: {

                // Use for tracking initialisation state
                init: false,

                // Render Template
                render: function( templateElement ){

                    // Initialise helper if haven't 
                    if(this.init === false) this.extend();

                    if(templateElement === "" || templateElement === null ){
                        return false;
                    }
                    
                    //var templateSource = document.getElementById( $(templateElement).attr("ID") ).innerHTML;
                    var templateSource = $(templateElement).html();
                    
                    //console.log( Handlebars.compile(templateSource) );
                    
                    if(templateSource !== "" || templateSource !== null){
                        // Compile handlebars and return
                        return Handlebars.compile(templateSource);
                    }
                    return false;
                },

                // Extend such as additional kelpers
                extend: function(){

                    // debug helper
                    // usage: {{debug}} or {{debug someValue}}
                    // from: @commondream (http://thinkvitamin.com/code/handlebars-js-part-3-tips-and-tricks/)
                    Handlebars.registerHelper("debug", function (optionalValue) {
                        console.log("Current Context");
                        console.log("====================");
                        console.log(this);

                        if (optionalValue) {
                            console.log("Value");
                            console.log("====================");
                            console.log(optionalValue);
                        }
                    });

                    Handlebars.registerHelper('reverse', function (arr) {

                        //console.log(typeof(arr))
                        if (global.polyfill_isArray(arr)) {
                            arr.reverse();
                        } else {
                            arr;
                        }

                    });

                    Handlebars.registerHelper('str_concatnate', function (a, b, opts) {
                        var str_combined = a + '' + b;
                        return new Handlebars.SafeString(str_combined);
                    });

                    Handlebars.registerHelper('str_replace', function (a, b, c, opts) {
                        var str_replaced = a.replace(b, c);
                        return new Handlebars.SafeString(str_replaced);
                    });

                    // Provides conditional string checks
                    Handlebars.registerHelper('if_eq', function (a, b, opts) {
                        if (a === b) {
                            return opts.fn(this);
                        } else {
                            return opts.inverse(this);
                        }
                    });

                    // Checks if string contains
                    Handlebars.registerHelper('if_contains', function (a, b, opts) {
                        if (a.indexOf(b) >= 0) {
                            return opts.fn(this);
                        } else {
                            return opts.inverse(this);
                        }
                    });

                    // Checks if obj contains
                    Handlebars.registerHelper('if_obj_contains', function (a, b, c, opts) {

                        var obj_founds = global.search_object_by_key(a, b, c);

                        if (obj_founds.length > 0) {
                            return opts.fn(this);
                        } else {
                            return opts.inverse(this);
                        }
                    });

                    // Comparsion Check
                    Handlebars.registerHelper('if_compare', function (lvalue, operator, rvalue, opts) {

                        var operators, result;

                        if (arguments.length < 3) {
                            throw new Error("Handlerbars Helper 'compare' needs 2 parameters");
                        }

                        if (opts === undefined) {
                            opts = rvalue;
                            rvalue = operator;
                            operator = "===";
                        }

                        operators = {
                            '==': function (l, r) {
                                return l == r;
                            },
                            '===': function (l, r) {
                                return l === r;
                            },
                            '!=': function (l, r) {
                                return l != r;
                            },
                            '!==': function (l, r) {
                                return l !== r;
                            },
                            '<': function (l, r) {
                                return l < r;
                            },
                            '>': function (l, r) {
                                return l > r;
                            },
                            '<=': function (l, r) {
                                return l <= r;
                            },
                            '>=': function (l, r) {
                                return l >= r;
                            },
                            'typeof': function (l, r) {
                                return typeof l == r;
                            }
                        };

                        if (!operators[operator]) {
                            throw new Error("Handlerbars Helper 'compare' doesn't know the operator " + operator);
                        }

                        result = operators[operator](lvalue, rvalue);

                        if (result) {
                            return opts.fn(this);
                        } else {
                            return opts.inverse(this);
                        }

                    });

                    //  Format a date
                    //  @link https://github.com/phstc/jquery-dateFormat
                    Handlebars.registerHelper('DateFormat', function (input, dateformat) {

                        // Normalize date to unix time
                        var unixTimestamp = new Date().getTime(input);
                        unixTimestamp = new Date(unixTimestamp).toISOString();

                        dateformat = dateformat == '' ? 'dd/MM/yyyy' : dateformat;

                        if (dateformat == 'pretty') {
                            return new Handlebars.SafeString($.format.prettyDate(new Date(unixTimestamp)));
                        } else {
                            return new Handlebars.SafeString($.format.date(new Date(unixTimestamp), dateformat));
                        }

                    });


                    // Finished initialisation
                    this.init = true;

                }
  
  
            },

            lazyLoad: function(){

                if(typeof(LazyLoad) != 'undefined'){

                    // @link https://github.com/verlok/lazyload
                    // Instantiate LazyLoad and make available for in Global JS scope
                    window.lazyLoadInstance = new LazyLoad({      
                        //use_native: false, // Use browser native implementation if available
                        load_delay: 300, // Set delay loading when there's inline anchor so that it scrolls to the right spot
                    }, global.elements.lazyload );


                    /* 
                    BROWSER'S NATIVE PRINT EVENTS LISTENERS
                    --------------------------------------- 
                    In order to make browsers load all the images before printing
                    even if the user doesn't press the custom print button. 
                    NOTE that this works only partially in some browsers! */
                    var isSafari = /^((?!chrome|android).)*safari/i.test(
                        navigator.userAgent
                    );
                    if (!isSafari) {
                        window.onbeforeprint = function() {
                            window.lazyLoadInstance.loadAll();
                        };
                    } else {
                        // Safari doesn't support the onbeforeprint event
                        var mediaQueryList = window.matchMedia("print");
                        mediaQueryList.addListener(function(mql) {
                            if (mql.matches) {
                                window.lazyLoadInstance.loadAll();
                            }
                        });
                    }

                }

            },


            wow: function() {

                if(typeof(WOW) != 'undefined'){
                    //Wow js
                    wow = new WOW({
                        mobile:true,     
                    })
                    wow.init();
                }

            },

            

           

            

        },
        /***********************************************************
        // PAGE SPECIFIC FUNCTIONS
        ***********************************************************/
        pages = function () {
            
            // Specific functions only required Use CSS classname on body tag
            if ($(global.elements.body).hasClass('page-home')) { // Home or Front-Page
                
            }

            if ($(global.elements.body).hasClass('post') ) { // Post or Page

            }

            if ($(global.elements.body).hasClass('search')) { // Search
                // Inline Code here ...
            }

            if ($(global.elements.body).hasClass('archives')) { // Archives, Catgory, Tags, etc.
                // Inline Code here ...
            }

            if ($(global.elements.body).hasClass('category') || $(global.elements.body).hasClass('category-news')) { // Category, Archives, Tags, etc.
                // Inline Code here ...
            }

            if ($(global.elements.body).hasClass('page-template-default')) {
                // Inline Code here ...
            }

            if ($(global.elements.body).hasClass('page')) {
               
                    
            }

            /* Other non-page specific functions for every page */
            /*
            $(global.elements.iframes).each(function (i, iframe) {

                //Exclude Ads from Responsive iframe wrapper
                if ($(iframe).src !== '//tpc.googlesyndication.com/safeframe/') {
                    //$(iframe).wrap("<div class='embed-responsive embed-responsive-16by9'></div>");
                }
            });
            */








        },

        /***********************************************************
        // Other functions as required
        ***********************************************************/
        misc_func = function () {};

        // Initialise JS Class and functions
        return {
            
            init: function () {
                
                // DEBUG RELATED
                if (global.debug_mode) {
                    console.log("Debug Mode: " + global.debug_mode);
                }

                // Page Specific functions
                pages();

                // Components Used throughout the site
                //components.stickyHeader();
                components.wow();
                components.lazyLoad();
                components.modal.inline();
                components.modal.iframe();
                components.smoothScroll();
                
                
                

            }
        };

}());




// Document Ready

$(document).ready( function() {

    SparkDigital.init();


});

$(window).on('load', function(){

  
});

$(window).resize(function() {
    setTimeout(function() {
    },120);
});// end window resize function


